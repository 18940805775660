import { getFormField, inputHandler } from '../../helper/utils'
import { checkBox, formFields, initialData, prices } from './constant'
import { string } from '../../helper/constant'
import { useState } from 'react'
import './donate.scss'

const Donate: React.FC = () => {
  const [data, setData] = useState(initialData)

  const submitHandler = (event: any) => {
    event.preventDefault()
  }

  return (
    <div>
      <form onSubmit={(e) => submitHandler(e)}>
        <section className='donation-info-section light-pink-bg'>
          <div className='container'>
            <div className='row'>
              <div className='form'>
                <h2>Donation Information</h2>
                <strong>Select Gift Amount *</strong>
                <div className='amount-section'>
                  {prices.map((item) => {
                    return (
                      <div
                        className={`amount-btn btn-orange ${
                          item.value === String(data.amount) && 'btn-blue'
                        }`}
                        onClick={() => setData((prev: any) => ({ ...prev, amount: item.value }))}
                      >
                        {item.text}
                      </div>
                    )
                  })}
                  <div className='amount-btn btn-orange enter-amount'>Enter an Amount</div>

                  <div className='amount-btn btn-orange input-box'>
                    <input
                      type='number'
                      min='1'
                      placeholder='$ --'
                      onChange={(e) => inputHandler(setData, e, 'amount')}
                      required
                    />
                  </div>
                </div>
                <div className='checkbox'>
                  {checkBox.map((item) => {
                    return (
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type={item.type}
                          value=''
                          id={item.id}
                          name={item.name}
                          onChange={(e) => inputHandler(setData, e, item.name, item.type)}
                        />
                        <label className='form-check-label'>{item.label}</label>
                      </div>
                    )
                  })}
                </div>

                <div className='information-form-secton'>
                  <h2>Your Information</h2>
                  <p>{string}</p>

                  {formFields.map((item) => {
                    return getFormField(item, setData)
                  })}
                  <input type='submit' className='btn btn-blue' value='DONATE NOW' />
                  <div className='note'>
                    <p>{string}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  )
}

export default Donate
