import React from 'react'
import { headerBtn } from '../constant';
import './HeaderBtn.scss';

const HeaderBtn: React.FC = () => {
  return (
    <div className='header-button'>
      {headerBtn.map((item, index) => (
        <button className={`btn ${item.class}`} key={index} onClick={item.onclick}>
          {item.text}
          {item.subDec && <span className="sub-desc">{item.subDec}</span>}
        </button>
      ))}
    </div>
  )
}

export default HeaderBtn
