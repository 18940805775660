export const prices = [
  { text: '$25.00', value: '25' },
  { text: '$50.00', value: '50' },
  { text: '$75.00', value: '75' },
  { text: '$100.00', value: '100' },
  { text: '$250.00', value: '250' },
]

export const checkBox = [
  {
    type: 'checkbox',
    name: 'repeatGift',
    label: 'Yes, automatically repeat this gift every month.,',
    id: 'flexCheckDefault',
  },
  {
    type: 'checkbox',
    name: 'tributeDonation',
    label: 'This is a tribute donation.',
    id: 'flexCheckChecked',
  },
]

export const formFields = [
  {
    label: 'First Name *',
    inputType: 'input',
    name: 'firstName',
    placeholder: 'Donationtthew',
    required: true,
  },
  {
    label: 'Last Name *',
    inputType: 'input',
    name: 'lastName',
    placeholder: 'Moneyman',
    required: true,
  },
  {
    label: 'Email Address *',
    inputType: 'email',
    name: 'emailAddress',
    placeholder: 'Enter Email Address',
    required: true,
  },
  {
    label: 'Mobile Phone',
    inputType: 'number',
    name: 'mobilePhone',
    placeholder: 'Enter Mobile Phone',
  },
  {
    label: 'State of Residence',
    inputType: 'select',
    name: 'stateOfResidence',
    options: [
      { value: '--', label: '--' },
      { value: '---', label: '---' },
      // Add other options here if needed
    ],
  },
  {
    label: 'I am interested in:',
    inputType: 'checkboxes',
    name: 'interests',
    options: [
      { id: 'flexCheckDefault1', label: 'Lorem' },
      { id: 'flexCheckChecked2', label: 'Ipsum' },
      { id: 'flexCheckChecked3', label: 'Dolor Sit' },
      { id: 'flexCheckChecked4', label: 'Amet' },
    ],
  },
  {
    label: 'Lorem ipsum dolor sit amet:',
    inputType: 'select',
    name: 'LoremIpsum',
    options: [
      { value: '--', label: '--' },
      { value: '---', label: '---' },
      // Add other options here if needed
    ],
  },
  {
    label: 'I am over 21 years of age*',
    inputType: 'checkbox',
    name: 'over21',
    required: true,
  },
]

export const getIntrests = (prev: any, e: any, name: string) => {
  return prev.interests.map((item: any) => {
    let newItems = item
    if (item.hasOwnProperty(name)) {
      newItems[name] = e.target.checked
    }
    return newItems
  })
}

export const initialData = {
  amount: 0 as number,
  repeatGift: false,
  tributeDonation: false,
  firstName: '',
  lastName: '',
  emailAddress: 'Enter Email Address',
  mobilePhone: 'Enter Mobile Phone',
  stateOfResidence: '',
  interests: [
    { flexCheckDefault1: false },
    { flexCheckChecked2: false },
    { flexCheckChecked3: false },
    { flexCheckChecked4: false },
  ] as any,
  over21: false,
}
